// ExecutionTime.jsx
import React, { useEffect, useCallback } from 'react';
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
  ChartLegend,
} from '@progress/kendo-react-charts';
import { getExecutionTime } from '../../Services/ExecutionService';
import { Loader } from 'smart-react';

/**
 * A Bar chart to show both Run Sets and Test Cases Success Failures
 * @param {*} data of success Failure
 * @returns
 */
export const TestCaseExecutionTime = ({ dates }) => {
  const [loading, setLoading] = React.useState(false);

  const [data, setData] = React.useState([]);

  /**
   * Get Analytics Data
   */
  const getAnalyticsData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getExecutionTime({
        moduleName: 'StatusSummary',
        isServiceWorker: false,
        data: { ...dates },
      });
      setData(response?.executionTime ?? []);
    } catch (error) {
      console.error('Error fetching analytics data:', error);
    }
    setLoading(false);
  }, [dates]);

  useEffect(() => {
    getAnalyticsData();
  }, [getAnalyticsData]);

  return (
    <div className='success-failure-test-cases test-cases'>
      {loading && <Loader />}
      <Chart>
        <ChartCategoryAxis>
          <ChartCategoryAxisItem
            labels={{
              color: '#666',
              format: 'd',
              rotation: 'auto',
            }}
            categories={data.map((item) => item.Date)}
          />
        </ChartCategoryAxis>
        <ChartValueAxis>
          <ChartValueAxisItem
            title={{ text: 'Elapsed Time (ms)' }}
            justified={true}
            labels={{
              format: '{0}',
              color: '#666',
            }}
          />
        </ChartValueAxis>
        <ChartSeries>
          <ChartSeriesItem
            type='area' // Change to 'area' for an area chart
            data={data.map((item) => item.TotalExecutionTime)}
            name='Total Execution Time'
            color='#27ab49' // Area color
            opacity={0.4}
          />
        </ChartSeries>
        {/* Tooltip */}
        <ChartTooltip />
        {/* Legend */}
        <ChartLegend position='bottom' padding={20} margin={20} />
      </Chart>
    </div>
  );
};

export default TestCaseExecutionTime;
