import React from 'react';
/**
 * data Columns for grid and mobile view
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The Data Columns Array
 */
export const ApplicationFlowDataColumns = () => {
  const dataColumns = [
    {
      field: 'row_no',
      title: 'Row No',
      editable: false,
      show: false,
      width: '250px',
      width: '250px',
      minResizableWidth: 150,
      filterable: true,
    },
    {
      field: 'tenant_id',
      title: 'Tenant',
      editable: false,
      show: false,
      width: '200px',
      minResizableWidth: 150,
      filterable: false,
    },
    {
      field: 'uc_ossi_app_test_id',
      title: 'Test',
      editable: false,
      show: true,
      width: '250px',
      minResizableWidth: 50,
      filterable: false,
    },
    {
      field: 'uc_ossi_run_num',
      title: 'Run #',
      editable: false,
      show: true,
      width: '150px',
      minResizableWidth: 50,
      filterable: false,
    },
    {
      field: 'uc_ossi_line_num',
      title: 'Line #',
      editable: false,
      show: true,
      width: '150px',
      minResizableWidth: 50,
      filterable: false,
    },
    {
      field: 'uc_ossi_test_case_status',
      title: 'Status',
      editable: false,
      show: true,
      width: '150px',
      minResizableWidth: 50,
      filterable: false,
    },
    {
      field: 'uc_ossi_test_case_action',
      title: 'Case Action',
      editable: false,
      show: true,
      width: '500px',
      minResizableWidth: 50,
      filterable: false,
    },
    {
      field: 'uc_ossi_test_case_cmnt',
      title: 'Comment',
      editable: false,
      show: true,
      width: '150px',
      minResizableWidth: 50,
      filterable: false,
    },
    {
      field: 'uc_ossi_screenshot_index',
      title: 'Screenshot Index',
      editable: false,
      show: true,
      width: '200px',
      minResizableWidth: 50,
      filterable: false,
    },
    {
      field: 'uc_ossi_start_ms',
      title: 'Start (ms)',
      editable: false,
      show: true,
      width: '150px',
      minResizableWidth: 50,
      filterable: false,
    },
  ];

  return dataColumns;
};
export default ApplicationFlowDataColumns;
