import React from 'react';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { Card, CardHeader } from '@progress/kendo-react-layout';
import { ErrorBoundary } from 'react-error-boundary';
import {
  ErrorFallback,
  deleteFilter,
  applyFilter,
  saveFilters,
} from 'smart-react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

import {
  EXECUTIONRESULTS,
  EXECUTIONCOMMANDS,
  EXECUTIONFIELDENTRIES,
  EXECUTIONSCREENSHOTS,
  EXECUTIONAPPLICATIONFLOW,
} from '../../../../constants/applicationConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { DataTableHoc, Loader } from 'smart-react';
import { GridColumnsList } from '../Components/Filter/ResultFilterElements';
import ResultDataColumns from '../Components/DataColumns/ResultDataColumns';
import CommandDataColumns from '../Components/DataColumns/CommandDataColumns';
import { GridColumnsList as CommandGridColumnsList } from '../Components/Filter/CommandFilterElements';
import FieldEntriesDataColumns from '../Components/DataColumns/FieldEntriesDataColumns';
import { GridColumnsList as FieldEntriesGridColumnsList } from '../Components/Filter/FieldEntriesFilterElements';
import ScreenshotsDataColumns from '../Components/DataColumns/ScreenshotsDataColumns';
import { GridColumnsList as ScreenshotGridColumnsList } from '../Components/Filter/SceeenshotFilterElements';
import ApplicationFlowDataColumns from '../Components/DataColumns/ApplicationFlowDataColumns';
import { GridColumnsList as ApplicationFlowColumnsList } from '../Components/Filter/ApplicationFlowFilterElements';
import {
  ROW_LAYOUTS,
  PAGE_SIZE,
} from '../../../../constants/applicationConstants';
const BaseResultDataTable = React.lazy(
  () => import('../Components/DataTable/ResultDataTable'),
);
const ResultDataTable = DataTableHoc(BaseResultDataTable);
const BaseCommandDataTable = React.lazy(
  () => import('../Components/DataTable/CommandDataTable'),
);
const CommandDataTable = DataTableHoc(BaseCommandDataTable);
const BaseFieldEntriesDataTable = React.lazy(
  () => import('../Components/DataTable/FieldEntriesDataTable'),
);
const FieldEntriesDataTable = DataTableHoc(BaseFieldEntriesDataTable);

const BaseScreenshotsDataTable = React.lazy(
  () => import('../Components/DataTable/ScreenshotsDataTable'),
);
const ScreenshotsDataTable = DataTableHoc(BaseScreenshotsDataTable);
const BaseApplicationFlowDataTable = React.lazy(
  () => import('../Components/DataTable/ApplicationFlowDataTable'),
);
const ApplicationFlowDataTable = DataTableHoc(BaseApplicationFlowDataTable);
import './ExecutionDetail.scss';
/**
 * Process ExecutionDetail Module
 * @returns {JSX.Element} The ExecutionDetail view.
 */
const ExecutionDetail = () => {
  const { state } = useLocation();
  const [userData, setUserData] = React.useState([]);
  // used to check API is ready to call
  const [isReady, setIsReady] = React.useState(false);
  const { dataSet } = state;
  const [selected, setSelected] = React.useState(0);
  const handleSelect = (e) => {
    setSelected(e.selected);
  };
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className="container mx-auto">
        <React.Suspense fallback={<Loader />}>
          <div className="execution-main main-page-section cards-layout-page container mx-auto add-page-section">
            <Card className="tophead">
              <CardHeader>
                <div className="action-buttons-container">
                  <Link
                    className="action-icon-wrapper"
                    to={`/autest/executions`}
                  >
                    <FontAwesomeIcon className="k-mr-2" icon={faArrowLeft} />
                  </Link>
                  <p className="k-align-items-center k-d-flex k-mb-0 display-text">
                    Execution: {dataSet?.uc_ossi_test_case_id}
                  </p>
                </div>
              </CardHeader>
            </Card>
            <Card>
              <div className="execution-details">
                <TabStrip selected={selected} onSelect={handleSelect}>
                  <TabStripTab title="Result">
                    <div className="container mx-auto">
                      <React.Suspense fallback={<Loader />}>
                        <ResultDataTable
                          deleteFilter={deleteFilter}
                          applyFilter={applyFilter}
                          saveFilters={saveFilters}
                          moduleName={EXECUTIONRESULTS}
                          dataColumns={ResultDataColumns()}
                          gridColumnsList={GridColumnsList}
                          dataTableName={EXECUTIONRESULTS}
                          setIsReady={setIsReady}
                          isReady={isReady}
                          rowLayoutConfigName={`${EXECUTIONRESULTS}.DataGrid.RowLayout`}
                          pageLengthConfigName={`${EXECUTIONRESULTS}.DataGrid.ItemsPerPage`}
                          dataSet={userData}
                          setDataSet={setUserData}
                          initialSort={{
                            field: 'id',
                            dir: 'desc',
                          }}
                          isCardonMobileView={false}
                          total={process.env.DefaultLimit}
                          defaultRowLayout={process.env.RowLayout}
                          rowLayouts={ROW_LAYOUTS}
                          pageSizes={PAGE_SIZE}
                          execution={dataSet}
                        />
                      </React.Suspense>
                    </div>
                  </TabStripTab>
                  <TabStripTab title="Commands">
                    <div className="container mx-auto">
                      <React.Suspense fallback={<Loader />}>
                        <CommandDataTable
                          deleteFilter={deleteFilter}
                          applyFilter={applyFilter}
                          saveFilters={saveFilters}
                          moduleName={EXECUTIONCOMMANDS}
                          dataColumns={CommandDataColumns()}
                          gridColumnsList={CommandGridColumnsList}
                          dataTableName={EXECUTIONCOMMANDS}
                          setIsReady={setIsReady}
                          isReady={isReady}
                          rowLayoutConfigName={`${EXECUTIONCOMMANDS}.DataGrid.RowLayout`}
                          pageLengthConfigName={`${EXECUTIONCOMMANDS}.DataGrid.ItemsPerPage`}
                          dataSet={userData}
                          setDataSet={setUserData}
                          initialSort={{
                            field: 'id',
                            dir: 'desc',
                          }}
                          isCardonMobileView={false}
                          total={process.env.DefaultLimit}
                          defaultRowLayout={process.env.RowLayout}
                          rowLayouts={ROW_LAYOUTS}
                          pageSizes={PAGE_SIZE}
                          execution={dataSet}
                        />
                      </React.Suspense>
                    </div>
                  </TabStripTab>
                  <TabStripTab title="Field Entries">
                    <div className="container mx-auto">
                      <React.Suspense fallback={<Loader />}>
                        <FieldEntriesDataTable
                          deleteFilter={deleteFilter}
                          applyFilter={applyFilter}
                          saveFilters={saveFilters}
                          moduleName={EXECUTIONFIELDENTRIES}
                          dataColumns={FieldEntriesDataColumns()}
                          gridColumnsList={FieldEntriesGridColumnsList}
                          dataTableName={EXECUTIONFIELDENTRIES}
                          setIsReady={setIsReady}
                          isReady={isReady}
                          rowLayoutConfigName={`${EXECUTIONFIELDENTRIES}.DataGrid.RowLayout`}
                          pageLengthConfigName={`${EXECUTIONFIELDENTRIES}.DataGrid.ItemsPerPage`}
                          dataSet={userData}
                          setDataSet={setUserData}
                          initialSort={{
                            field: 'id',
                            dir: 'desc',
                          }}
                          isCardonMobileView={false}
                          total={process.env.DefaultLimit}
                          defaultRowLayout={process.env.RowLayout}
                          rowLayouts={ROW_LAYOUTS}
                          pageSizes={PAGE_SIZE}
                          execution={dataSet}
                        />
                      </React.Suspense>
                    </div>
                  </TabStripTab>
                  <TabStripTab title="Screenshots">
                    <div className="container mx-auto">
                      <React.Suspense fallback={<Loader />}>
                        <ScreenshotsDataTable
                          deleteFilter={deleteFilter}
                          applyFilter={applyFilter}
                          saveFilters={saveFilters}
                          moduleName={EXECUTIONSCREENSHOTS}
                          dataColumns={ScreenshotsDataColumns()}
                          gridColumnsList={ScreenshotGridColumnsList}
                          dataTableName={EXECUTIONSCREENSHOTS}
                          setIsReady={setIsReady}
                          isReady={isReady}
                          rowLayoutConfigName={`${EXECUTIONSCREENSHOTS}.DataGrid.RowLayout`}
                          pageLengthConfigName={`${EXECUTIONSCREENSHOTS}.DataGrid.ItemsPerPage`}
                          dataSet={userData}
                          setDataSet={setUserData}
                          initialSort={{
                            field: 'id',
                            dir: 'desc',
                          }}
                          isCardonMobileView={false}
                          total={process.env.DefaultLimit}
                          defaultRowLayout={process.env.RowLayout}
                          rowLayouts={ROW_LAYOUTS}
                          pageSizes={PAGE_SIZE}
                          execution={dataSet}
                        />
                      </React.Suspense>
                    </div>
                  </TabStripTab>
                  <TabStripTab title="Application Flow">
                    <div className="container mx-auto">
                      <React.Suspense fallback={<Loader />}>
                        <ApplicationFlowDataTable
                          deleteFilter={deleteFilter}
                          applyFilter={applyFilter}
                          saveFilters={saveFilters}
                          moduleName={EXECUTIONAPPLICATIONFLOW}
                          dataColumns={ApplicationFlowColumnsList()}
                          gridColumnsList={ApplicationFlowDataColumns}
                          dataTableName={EXECUTIONAPPLICATIONFLOW}
                          setIsReady={setIsReady}
                          isReady={isReady}
                          rowLayoutConfigName={`${EXECUTIONAPPLICATIONFLOW}.DataGrid.RowLayout`}
                          pageLengthConfigName={`${EXECUTIONAPPLICATIONFLOW}.DataGrid.ItemsPerPage`}
                          dataSet={userData}
                          setDataSet={setUserData}
                          initialSort={{
                            field: 'id',
                            dir: 'desc',
                          }}
                          isCardonMobileView={false}
                          total={process.env.DefaultLimit}
                          defaultRowLayout={process.env.RowLayout}
                          rowLayouts={ROW_LAYOUTS}
                          pageSizes={PAGE_SIZE}
                          execution={dataSet}
                        />
                      </React.Suspense>
                    </div>
                  </TabStripTab>
                </TabStrip>
              </div>
            </Card>
          </div>
        </React.Suspense>
      </div>
    </ErrorBoundary>
  );
};
export default ExecutionDetail;
