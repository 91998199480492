import React, { lazy, useState, useEffect, Suspense, useCallback } from 'react';
import { TileLayout } from '@progress/kendo-react-layout';
import { Button } from '@progress/kendo-react-buttons';
import { ErrorBoundary } from 'react-error-boundary';
import { Link } from 'react-router-dom';
import { orderBy } from '@progress/kendo-data-query';
import { GlobalContext, ErrorFallback, Loader } from 'smart-react';
import {
  getLayout,
  handleReposition,
  saveLayout,
} from '../../../../Utils/Layouts/tileLayoutHandler';
import './../../../../assets/scss/common/Dashboard.scss';
import './AutestDashboard.scss';
import { AUTEST_DASHBOARD_TILELAYOUT } from '../../../../constants/applicationConstants';
import TotalExecutionsCard from '../Components/Cards/TotalExecutionsCard';
import { getTotalCounts } from '../../Execution/Services/ExecutionService';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { DEVICE_TYPES } from '../../../../constants/eventDataTypes';
const ExecutionTypeCard = lazy(
  () => import('../Components/Cards/ExecutionTypeCard'),
);
const TestCaseCard = lazy(() => import('../Components/Cards/TestCaseCard'));
const DashboardCountCard = lazy(
  () => import('./../../../Core/Cards/DashboardCountCard'),
);
/**
 * Autest Dashboard main screen
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The AutestDashboard component.
 */
const AutestDashboard = () => {
  const { deviceInfo } = React.useContext(GlobalContext);

  const tilelayout = AUTEST_DASHBOARD_TILELAYOUT;
  const [isLoading, setIsLoading] = React.useState(false);

  const [metrics, setMetrics] = useState({
    totalTests: '--',
    totalTestCases: '--',
    totalRunSets: '--',
    totalCommands: '--',
    totalApplicationFlows: '--',
  });
  /**
   * Default Date Range to 1 week
   * @returns
   */
  const getDefaultDateRange = () => {
    const today = new Date();
    const lastWeek = new Date(today);
    lastWeek.setDate(today.getDate() - 7);
    return {
      startDate: lastWeek.toISOString().split('T')[0],
      endDate: today.toISOString().split('T')[0],
    };
  };

  const [dates, setDates] = useState(
    JSON.parse(
      localStorage.getItem(`${AUTEST_DASHBOARD_TILELAYOUT}_DATE_FILTER`),
    )?.dates ?? getDefaultDateRange(),
  );

  /**
   * Handle Date Change Event
   * @param {*} e
   * @param {*} field
   */
  const handleChange = (e, field) => {
    setDates({ ...dates, [field]: e.value });
    updateSelectedDate({ ...dates, [field]: e.value }, selectedRange);
  };

  /**
   * get Analytics Data
   */
  const getAnalyticsData = React.useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await getTotalCounts({
        moduleName: 'AutestSummary',
        isServiceWorker: false,
        data: {},
      });
      setMetrics({
        totalTests: response?.analyticsData?.TotalTests ?? '--',
        totalTestCases: response?.analyticsData?.TotalTestCases ?? '--',
        totalRunSets: response?.analyticsData?.TotalRunSets ?? '--',
        totalCommands: response?.analyticsData?.TotalCommands ?? '--',
        totalApplicationFlows:
          response?.analyticsData?.TotalApplicationFlows ?? '--',
      });

      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching analytics data:', error);
    }
  }, [dates]);

  /**
   * Use Effect to call getAnalyticsData Callback
   */
  useEffect(() => {
    getAnalyticsData();
  }, [getAnalyticsData]);
  /**
   * Define Layout for Desktop, Tablet, Mobile
   */
  const homeDefaultLayout = {
    LargeScreenLayout: [
      {
        col: 0,
        colSpan: 1,
        rowSpan: 1,
      },
    ],
    TabletScreenLayout: [
      {
        col: 0,
        colSpan: 1,
        rowSpan: 1,
      },
    ],
    MobileScreenLayout: [
      {
        col: 0,
        colSpan: 2,
        rowSpan: 1,
      },
    ],
  };

  const [allowReposition, setAllowReposition] = useState(false);

  const tiles = [
    {
      item: (
        <>
          {isLoading && <Loader />}
          <Link className='dashboard-action' to={`/autest/tests`}>
            <DashboardCountCard
              cardClass={'blue-card'}
              label={'Total Tests'}
              value={metrics.totalTests}
              iconClass={'blue'}
              icon={'sis-ico-test'}
            />
          </Link>
        </>
      ),
      resizable: allowReposition,
      reorderable: allowReposition,
      name: 'TotalTests',
      id: 1,
      layout: {
        LargeScreenLayout: [
          {
            col: 0,
            colSpan: 1,
            rowSpan: 1,
          },
        ],
        TabletScreenLayout: [
          {
            col: 0,
            colSpan: 2,
            rowSpan: 1,
          },
        ],
        MobileScreenLayout: [
          {
            col: 0,
            colSpan: 4,
            rowSpan: 1,
          },
        ],
      },
    },
    {
      item: (
        <>
          {isLoading && <Loader />}
          <Link className='dashboard-action' to={`/autest/testcases`}>
            <DashboardCountCard
              cardClass={'green-card'}
              label={'Total Test Cases'}
              value={metrics.totalTestCases}
              iconClass={'green'}
              icon={'sis-ico-test-case'}
            />
          </Link>
        </>
      ),
      resizable: allowReposition,
      reorderable: allowReposition,
      name: 'TotalTestCases',
      id: 2,
      layout: {
        LargeScreenLayout: [
          {
            col: 0,
            colSpan: 1,
            rowSpan: 1,
          },
        ],
        TabletScreenLayout: [
          {
            col: 0,
            colSpan: 2,
            rowSpan: 1,
          },
        ],
        MobileScreenLayout: [
          {
            col: 0,
            colSpan: 4,
            rowSpan: 1,
          },
        ],
      },
    },
    {
      item: (
        <>
          {isLoading && <Loader />}
          <Link className='dashboard-action' to={`/autest/run-sets`}>
            <DashboardCountCard
              cardClass={'amber-card'}
              label={'Total Run Sets'}
              value={metrics.totalRunSets}
              iconClass={'amber'}
              icon={'sis-ico-run-set'}
            />
          </Link>
        </>
      ),
      resizable: allowReposition,
      reorderable: allowReposition,
      name: 'TotalRunSets',
      id: 3,
      layout: {
        LargeScreenLayout: [
          {
            col: 0,
            colSpan: 1,
            rowSpan: 1,
          },
        ],
        TabletScreenLayout: [
          {
            col: 0,
            colSpan: 2,
            rowSpan: 1,
          },
        ],
        MobileScreenLayout: [
          {
            col: 0,
            colSpan: 4,
            rowSpan: 1,
          },
        ],
      },
    },
    {
      item: (
        <>
          {isLoading && <Loader />}
          <Link className='dashboard-action' to={`/autest/commands`}>
            <DashboardCountCard
              cardClass={'yellow-card'}
              label={'Total Commands'}
              value={metrics.totalCommands}
              iconClass={'yellow'}
              icon={'sis-ico-info-commands-yellow'}
            />
          </Link>
        </>
      ),
      resizable: allowReposition,
      reorderable: allowReposition,
      name: 'TotalTests',
      id: 4,
      layout: {
        LargeScreenLayout: [
          {
            col: 0,
            colSpan: 1,
            rowSpan: 1,
          },
        ],
        TabletScreenLayout: [
          {
            col: 0,
            colSpan: 2,
            rowSpan: 1,
          },
        ],
        MobileScreenLayout: [
          {
            col: 0,
            colSpan: 4,
            rowSpan: 1,
          },
        ],
      },
    },
    {
      item: (
        <>
          {isLoading && <Loader />}
          <Link className='dashboard-action' to={`/autest/application-flow`}>
            <DashboardCountCard
              cardClass={'purple-card'}
              label={'Total Application Flows'}
              value={metrics.totalApplicationFlows}
              iconClass={'purple'}
              icon={'sis-ico-device-enrollments'}
            />
          </Link>
        </>
      ),
      resizable: allowReposition,
      reorderable: allowReposition,
      name: 'TotalTests',
      id: 5,
      layout: {
        LargeScreenLayout: [
          {
            col: 0,
            colSpan: 1,
            rowSpan: 1,
          },
        ],
        TabletScreenLayout: [
          {
            col: 0,
            colSpan: 2,
            rowSpan: 1,
          },
        ],
        MobileScreenLayout: [
          {
            col: 0,
            colSpan: 4,
            rowSpan: 1,
          },
        ],
      },
    },
    {
      header: (
        <div className='card-header'>
          <Link className='dashboard-action' to={`/autest/executions`}>
            <span className='k-card-title'>Executions by Type</span>
          </Link>
        </div>
      ),
      body: <ExecutionTypeCard dates={dates} />,
      resizable: allowReposition,
      reorderable: allowReposition,
      name: 'ExecutionType',
      id: 6,
      layout: {
        LargeScreenLayout: [
          {
            colSpan: 2,
            rowSpan: 3,
          },
        ],
        TabletScreenLayout: [
          {
            colSpan: 2,
            rowSpan: 3,
          },
        ],
        MobileScreenLayout: [
          {
            colSpan: 4,
            rowSpan: 3,
          },
        ],
      },
    },
    {
      header: (
        <div className='card-header'>
          <Link className='dashboard-action' to={`/autest/testcases`}>
            <span className='k-card-title'>Execution Trends</span>
          </Link>
        </div>
      ),
      body: <TestCaseCard dates={dates} />,
      resizable: allowReposition,
      reorderable: allowReposition,
      name: 'TestCases',
      layout: {
        LargeScreenLayout: [
          {
            colSpan: 3,
            rowSpan: 3,
          },
        ],
        TabletScreenLayout: [
          {
            colSpan: 2,
            rowSpan: 3,
          },
        ],
        MobileScreenLayout: [
          {
            colSpan: 4,
            rowSpan: 3,
          },
        ],
      },
      id: 7,
    },
    {
      header: (
        <div className='card-header'>
          <Link className='dashboard-action' to={`/autest/executions`}>
            <span className='k-card-title'>Test Executions</span>
          </Link>
        </div>
      ),
      body: <TotalExecutionsCard dates={dates} />,
      resizable: allowReposition,
      reorderable: allowReposition,
      name: 'TotalType',
      layout: {
        LargeScreenLayout: [
          {
            colSpan: 2,
            rowSpan: 3,
          },
        ],
        TabletScreenLayout: [
          {
            colSpan: 2,
            rowSpan: 3,
          },
        ],
        MobileScreenLayout: [
          {
            colSpan: 4,
            rowSpan: 3,
          },
        ],
      },
      id: 8,
    },
  ];

  /**
   * get Tile Layout Data
   * if data length is less then tiles length
   * it will duplicate data to match no of tiles.
   * @returns {Array} layoutData
   */
  const getDefaultLayout = (tilesData, layout) => {
    const filteredTiles = orderBy(tilesData ?? [], [
      { dir: 'asc', field: 'id' },
    ]);
    const layoutData = getLayout({
      tiles: filteredTiles, // an array of tile objects with ids
      tilelayout,
      deviceInfo,
      layout: layout,
    });

    // Ensure each tile gets its own layout, rather than filling with the same layout
    if (layoutData.length < tilesData.length) {
      return filteredTiles.map(
        (tile) => layoutData.find((l) => l.id === tile.id) || {},
      );
    }
    return layoutData;
  };

  const [data, setData] = useState(getDefaultLayout(tiles, homeDefaultLayout));

  useEffect(() => {
    setData(getDefaultLayout(tiles, homeDefaultLayout));
  }, [deviceInfo]);

  //Default Date option
  const dateRangeOptions = [
    { text: 'Last 7 Days', value: '7' },
    { text: 'Last 14 Days', value: '14' },
    { text: 'Last 30 Days', value: '30' },
    { text: 'Custom Date', value: 'custom' },
  ];

  const [selectedRange, setSelectedRange] = useState(
    JSON.parse(
      localStorage.getItem(`${AUTEST_DASHBOARD_TILELAYOUT}_DATE_FILTER`),
    )?.range ?? '7',
  );

  const [showCalendar, setShowCalendar] = useState(false);

  /**
   * Handle Date Range Event
   * @param {*} e
   * @returns
   */
  const handleRangeChange = (e) => {
    const value = e.target.value;
    setSelectedRange(value?.value);

    // Handle predefined date range selection
    const today = new Date();
    let startDate;
    switch (value?.value) {
      case '7':
        startDate = new Date(today);
        startDate.setDate(today.getDate() - 7);
        setShowCalendar(false);
        break;
      case '14':
        startDate = new Date(today);
        startDate.setDate(today.getDate() - 14);
        setShowCalendar(false);
        break;
      case '30':
        startDate = new Date(today);
        startDate.setDate(today.getDate() - 30);
        setShowCalendar(false);

        break;
      case 'custom':
        setShowCalendar(true);
        return;
      default:
        return;
    }

    // Set dates for predefined ranges
    let dates = {
      startDate: startDate.toISOString().split('T')[0],
      endDate: today.toISOString().split('T')[0],
    };
    setDates(dates);
    updateSelectedDate(dates, value?.value);
  };

  /**
   * Set Selected Date
   * @param {*} value
   */
  const updateSelectedDate = (value, range) => {
    localStorage.setItem(
      `${AUTEST_DASHBOARD_TILELAYOUT}_DATE_FILTER`,
      JSON.stringify({ range, dates: value }),
    );
  };
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className='home-page'>
        <Suspense fallback={<Loader />}>
          <section className='tile-header'>
            <div className='date-selector'>
              <DropDownList
                className='date-range-selector k-mr-2'
                data={dateRangeOptions}
                value={dateRangeOptions.find(
                  (option) => option.value === selectedRange,
                )}
                textField='text'
                dataItemKey='value'
                onChange={handleRangeChange}
              />
              {showCalendar && (
                <>
                  <div className='k-px-2'>
                    <label>Start Date:</label>
                    <DatePicker
                      value={new Date(dates.startDate)}
                      format={'MM-dd-yyyy'}
                      onChange={(e) => handleChange(e, 'startDate')}
                    />
                  </div>
                  <div className='k-px-2'>
                    <label>End Date:</label>
                    <DatePicker
                      value={new Date(dates.endDate)}
                      format={'MM-dd-yyyy'}
                      onChange={(e) => handleChange(e, 'endDate')}
                    />
                  </div>
                </>
              )}
              <Button
                className={`action-button`}
                icon={'refresh'}
                onClick={() => {
                  setDates({ ...dates });
                }}
                fillMode='outline'
                themeColor='primary'
              />
            </div>
            <Button
              fillMode={!allowReposition ? 'flat' : 'outline'}
              icon={!allowReposition ? 'edit' : ''}
              themeColor={'primary'}
              className=' edit-tiles'
              onClick={() =>
                saveLayout({
                  tilelayout,
                  data,
                  allowReposition,
                  setAllowReposition,
                  deviceInfo,
                })
              }
            >
              {!allowReposition ? '' : 'Done Editing'}
            </Button>
          </section>
          <TileLayout
            rowHeight={150}
            columns={deviceInfo?.type === DEVICE_TYPES.PHONE ? 4 : 5}
            positions={data}
            gap={{
              rows: 10,
              columns: 10,
            }}
            items={tiles}
            className='charts'
            onReposition={(e) => handleReposition(e, setData)}
          />
        </Suspense>
      </div>
    </ErrorBoundary>
  );
};

export default AutestDashboard;
