import React from 'react';
import './TotalExecutions.scss'; // Import custom CSS

/**
 * Total Executions Card
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The TotalExecutionsCard component.
 */
const TotalExecutionsCard = ({ subtitle, value, percentage }) => {
  // Progress Bar Style And Percentage
  const progressBarStyle = {
    width: `${percentage ?? 0}%`,
    backgroundColor: percentage > 0 ? '#4caf50' : 'grey',
  };

  return (
    <div className='custom-card'>
      <div className='custom-card-body'>
        <div className='sub-title'>
          <span className='sub-title-text success'>{subtitle}</span>
          <span className='value'>
            {value} <span className='percentage'>({percentage ?? 0}%)</span>
          </span>
        </div>
        <div className='progress-bar'>
          <div className='progress' style={progressBarStyle}></div>
        </div>
      </div>
    </div>
  );
};

export default TotalExecutionsCard;
