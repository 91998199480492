import { apiCall } from '../../../../Middleware/API/apiRequest';
import { getTokensFromStorage } from '../../../../Utils/Auth/AuthToken';
import {
  AUTEST_EXECUTIONS,
  AUTEST_ANALYTICS,
} from '../../../../constants/apiRoutes';
import {
  getGraphqlCacheResponse,
  setGraphqlCacheResponse,
} from '../../../../Utils/Storage/IndexDB/indexDbWrappers';
/**
 * list Executions.
 * @returns {Promise} - A promise that resolves with the list of Executions or rejects with an error.
 * @param props
 */
export const listExecution = async ({
  accessToken,
  limit,
  offset,
  filter,
  sort,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const data = {
    Filters: filter,
    pageNumber: offset === 0 ? 1 : offset,
    pageSize: limit,
    sort,
  };

  const response = await apiRequest({
    url: `${AUTEST_EXECUTIONS}/Filter`,
    method: 'POST',
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  if (response?.isError) {
    if (isServiceWorker) {
      let cacheResponse = await getGraphqlCacheResponse(
        headers,
        isServiceWorker,
      );
      return cacheResponse;
    }
    return response;
  }
  if (isServiceWorker) {
    await setGraphqlCacheResponse(headers, response, isServiceWorker);
  }
  return {
    executions: response.Payload,
    TotalCount: response?.TotalRecordCount,
  };
};

//#region Execution Analytics

/**
 * get Execution Analytics for ExecutionsSuccessFailure.
 * @returns {Promise} - A promise that resolves with the list of Execution Analytics for ExecutionsSuccessFailure or rejects with an error.
 * @param props
 */
export const getExecutionsSuccessFailure = async ({
  moduleName,
  isServiceWorker,
  listController,
  data,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${AUTEST_ANALYTICS}/ExecutionsSuccessFailure`,
    method: 'POST',
    moduleName,
    isServiceWorker,
    listController,
    accessToken,
    body: data,
  });
  return {
    executionsSuccessFailure: response.Payload,
  };
};

/**
 * get Execution Analytics for ExecutionsByModule.
 * @returns {Promise} - A promise that resolves with the list of Execution Analytics for ExecutionsByModule or rejects with an error.
 * @param props
 */
export const getExecutionsByModule = async ({
  moduleName,
  isServiceWorker,
  listController,
  data,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${AUTEST_ANALYTICS}/ExecutionsByModule`,
    method: 'POST',
    moduleName,
    isServiceWorker,
    listController,
    accessToken,
    body: data,
  });
  return {
    executionsByModule: response.Payload,
  };
};

/**
 * get Execution Analytics for ExecutionTypeData.
 * @returns {Promise} - A promise that resolves with the list of Execution Analytics for ExecutionTypeData or rejects with an error.
 * @param props
 */
export const getExecutionTypeData = async ({
  moduleName,
  isServiceWorker,
  listController,
  data,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${AUTEST_ANALYTICS}/ExecutionTypeData`,
    method: 'POST',
    moduleName,
    isServiceWorker,
    listController,
    accessToken,
    body: data,
  });
  return {
    invokeMethodTests: response.Payload,
  };
};

/**
 * get Execution Analytics for ExecutionsByTestType.
 * @returns {Promise} - A promise that resolves with the list of Execution Analytics for ExecutionsByTestType or rejects with an error.
 * @param props
 */
export const getExecutionsByTestType = async ({
  moduleName,
  isServiceWorker,
  listController,
  data,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${AUTEST_ANALYTICS}/ExecutionsByTestType`,
    method: 'POST',
    moduleName,
    isServiceWorker,
    listController,
    accessToken,
    body: data,
  });
  return {
    executionsRunByType: response.Payload,
  };
};

/**
 * get Execution Analytics for ExecutionTime.
 * @returns {Promise} - A promise that resolves with the list of Execution Analytics for ExecutionTime or rejects with an error.
 * @param props
 */
export const getExecutionTime = async ({
  moduleName,
  isServiceWorker,
  listController,
  data,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${AUTEST_ANALYTICS}/ExecutionTime`,
    method: 'POST',
    moduleName,
    isServiceWorker,
    listController,
    accessToken,
    body: data,
  });
  return {
    executionTime: response.Payload,
  };
};

//#endregion

//#region Autest Analytics

/**
 * get Autest Analytics for TotalCounts.
 * @returns {Promise} - A promise that resolves with the list of Autest Analytics for TotalCounts or rejects with an error.
 * @param props
 */
export const getTotalCounts = async ({
  moduleName,
  isServiceWorker,
  listController,
  data,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${AUTEST_ANALYTICS}/TotalCounts`,
    method: 'POST',
    moduleName,
    isServiceWorker,
    listController,
    accessToken,
    body: data,
  });
  return {
    analyticsData: response.Payload,
  };
};

/**
 * get Autest Analytics for ExecutionResultSummary.
 * @returns {Promise} - A promise that resolves with the list of Autest Analytics for ExecutionResultSummary or rejects with an error.
 * @param props
 */
export const getExecutionResultSummary = async ({
  moduleName,
  isServiceWorker,
  listController,
  data,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${AUTEST_ANALYTICS}/ExecutionResultSummary`,
    method: 'POST',
    moduleName,
    isServiceWorker,
    listController,
    accessToken,
    body: data,
  });
  return {
    executionsPassFail: response.Payload,
  };
};

/**
 * get Autest Analytics for ExecutionTotal.
 * @returns {Promise} - A promise that resolves with the list of Autest Analytics for ExecutionTotal or rejects with an error.
 * @param props
 */
export const getExecutionTotal = async ({
  moduleName,
  isServiceWorker,
  listController,
  data,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await apiRequest({
    url: `${AUTEST_ANALYTICS}/ExecutionTotal`,
    method: 'POST',
    moduleName,
    isServiceWorker,
    listController,
    accessToken,
    body: data,
  });
  return {
    ExecutionTotal: response.Payload,
  };
};

//#endregion

/**
 * Execution Api Call Wrapper Method.
 * @param {object} api - Contains details like url headers method and data.
 * @returns {Promise} - A promise that resolves with the data of Execution or rejects with an error.
 */
const apiRequest = async (request) => {
  let response;
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  request.headers = headers;
  try {
    response = await apiCall(request);
  } catch (e) {
    response = { isSuccess: false, message: e.message };
  }
  return response;
};
