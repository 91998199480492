import React, { useEffect, Suspense } from 'react';
import { Loader } from 'smart-react';
import TotalExecutionsData from '../Charts/TotalExecutionsData';
import { getExecutionTotal } from '../../../Execution/Services/ExecutionService';

/**
 * Total Executions Card
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The TotalExecutionsCard component.
 */
const TotalExecutionsCard = ({ dates }) => {
  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  /**
   * Get Analytics Data
   */
  const getAnalyticsData = React.useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await getExecutionTotal({
        moduleName: 'AutestSummary',
        isServiceWorker: false,
        data: { ...dates },
      });
      setData(response?.ExecutionTotal ?? []);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching analytics data:', error);
    }
  }, [dates]);

  useEffect(() => {
    getAnalyticsData();
  }, [getAnalyticsData]);
  return (
    <div>
      <Suspense fallback={<Loader />}>
        {isLoading && <Loader />}
        <TotalExecutionsData reportData={data ?? []} />
      </Suspense>
    </div>
  );
};

export default TotalExecutionsCard;
