import React from 'react';
import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
} from '@progress/kendo-react-charts';

/**
 * Pass Vs Fail Data Chart
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The PassVsFailData component.
 */
export const PassVsFailData = ({
  passedCount = 0,
  failedCount = 0,
  totalExecutions = 0,
}) => {
  const passRate = totalExecutions ? (passedCount / totalExecutions) * 100 : 0;

  const donutData = [
    { category: 'Passed', value: passedCount, color: '#bce3c5' },
    { category: 'Failed', value: failedCount, color: '#ececec' },
  ];

  return (
    <>
      <Chart style={{ width: '100px', height: '100px' }}>
        <ChartTooltip />
        <ChartSeries>
          <ChartSeriesItem
            type='donut'
            data={donutData}
            categoryField='category'
            field='value'
            colorField='color'
            holeSize={40}
          />
        </ChartSeries>
        <ChartLegend visible={false} />
      </Chart>
      <div className='donut-label'>{passRate?.toFixed(2) ?? 0}%</div>
    </>
  );
};

export default PassVsFailData;
