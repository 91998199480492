// TotalExecutionsData.jsx

import React from 'react';
import {
  Chart,
  ChartLegend,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
} from '@progress/kendo-react-charts';

/**
 * A Bar chart to show both Run Sets and Test Cases successfully executed
 * @param {*} reportData of success By Module
 * @returns
 */
export const TotalExecutionsData = ({ reportData }) => {
  return (
    <div className='success-by-module-result'>
      <Chart>
        <ChartLegend position='bottom' />
        <ChartCategoryAxis>
          <ChartCategoryAxisItem
            categories={reportData?.map((item) => item.ExecutionType)}
          />
        </ChartCategoryAxis>
        <ChartSeries>
          <ChartSeriesItem
            type='column'
            data={reportData?.map((item) => item.Passed)}
            name='Passed'
            color='#BCE3C5'
            labels={{
              color: '#666',
            }}
          />
          <ChartSeriesItem
            type='column'
            data={reportData?.map((item) => item.Failed)}
            name='Failed'
            color='#FA8072'
            labels={{
              color: '#666',
            }}
          />
        </ChartSeries>
        <ChartTooltip />
      </Chart>
    </div>
  );
};

export default TotalExecutionsData;
