import React, { lazy, useState, useEffect, Suspense, useCallback } from 'react';
import { TileLayout } from '@progress/kendo-react-layout';
import { Button } from '@progress/kendo-react-buttons';
import { ErrorBoundary } from 'react-error-boundary';
import { Link } from 'react-router-dom';
import { orderBy } from '@progress/kendo-data-query';
import { GlobalContext, ErrorFallback, Loader } from 'smart-react';
import {
  getLayout,
  handleReposition,
  saveLayout,
} from '../../../../Utils/Layouts/tileLayoutHandler';
import './../../../../assets/scss/common/Dashboard.scss';
import './ExecutionDashboard.scss';
import { EXECUTION_DASHBOARD_TILELAYOUT } from '../../../../constants/applicationConstants';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import TestCaseExecutionTime from '../Components/Charts/TestCaseExecutionTime';
import { getExecutionTypeData } from '../Services/ExecutionService';
import TotalExecutionsCard from '../Components/Cards/TotalExecutionsCard';
import PassVsFailData from '../Components/Charts/PassVsFailData';
import ExecutionTypeData from '../Components/Charts/ExecutionTypeData';
import { DEVICE_TYPES } from '../../../../constants/eventDataTypes';
const SuccessFailure = lazy(
  () => import('../Components/Charts/SuccessFailure'),
);
const TestsRunByType = lazy(
  () => import('../Components/Charts/TestsRunByType'),
);
const SuccessByModule = lazy(
  () => import('../Components/Charts/SuccessByModule'),
);

/**
 * Execution Dashboard main screen
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The ExecutionDashboard component.
 */
const ExecutionDashboard = () => {
  const { deviceInfo } = React.useContext(GlobalContext);

  const tilelayout = EXECUTION_DASHBOARD_TILELAYOUT;

  /**
   * Default Date Range to 1 week
   * @returns
   */
  const getDefaultDateRange = () => {
    const today = new Date();
    const lastWeek = new Date(today);
    lastWeek.setDate(today.getDate() - 7);
    return {
      startDate: lastWeek.toISOString().split('T')[0],
      endDate: today.toISOString().split('T')[0],
    };
  };

  const [dates, setDates] = useState(
    JSON.parse(
      localStorage.getItem(`${EXECUTION_DASHBOARD_TILELAYOUT}_DATE_FILTER`),
    )?.dates ?? getDefaultDateRange(),
  );

  /**
   * Handle Date Change Event
   * @param {*} e
   * @param {*} field
   */
  const handleChange = (e, field) => {
    setDates({ ...dates, [field]: e.value });
    updateSelectedDate({ ...dates, [field]: e.value }, selectedRange);
  };

  /**
   * Define Layout for Desktop, Tablet, Mobile
   */
  const homeDefaultLayout = {
    LargeScreenLayout: [
      {
        col: 0,
        colSpan: 1,
        rowSpan: 1,
      },
    ],
    TabletScreenLayout: [
      {
        col: 0,
        colSpan: 1,
        rowSpan: 1,
      },
    ],
    MobileScreenLayout: [
      {
        col: 0,
        colSpan: 4,
        rowSpan: 1,
      },
    ],
  };

  const [allowReposition, setAllowReposition] = useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [analyticsData, setAnalyticsData] = React.useState([]);
  const [totalExecutions, setTotalExecutions] = React.useState(0);
  const [passedCount, setPassedCount] = React.useState(0);
  const [failedCount, setFailedCount] = React.useState(0);

  /**
   * Get Analytics Data
   */
  const getAnalyticsData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getExecutionTypeData({
        moduleName: 'StatusSummary',
        isServiceWorker: false,
        data: { ...dates },
      });
      const fetchedData = response?.invokeMethodTests ?? [];
      if (fetchedData?.length > 0) {
        // Check for missing categories and append them with specific values if necessary
        const requiredCategories = [
          { category: 'STRESS', count: 200 },
          { category: 'REGRESSION', count: 400 },
        ];

        requiredCategories.forEach(({ category, count }) => {
          if (!fetchedData.some((item) => item.ExecutionMethod === category)) {
            // Append missing categories with the specified Count
            fetchedData.push({
              ExecutionMethod: category,
              Count: count,
            });
          }
        });
        // Calculate total executions, passed and failed
        const totalExecutions = fetchedData?.reduce(
          (sum, item) => sum + (item?.Passed ?? 0) + (item?.Failed ?? 0),
          0,
        );
        const passedCount = fetchedData?.reduce(
          (sum, item) => sum + (item?.Passed ?? 0),
          0,
        );
        const failedCount = fetchedData?.reduce(
          (sum, item) => sum + (item?.Failed ?? 0),
          0,
        );

        setFailedCount(failedCount ?? 0);
        setPassedCount(passedCount ?? 0);
        setTotalExecutions(totalExecutions ?? 0);
      }
      setAnalyticsData(fetchedData);
    } catch (error) {
      console.error('Error fetching analytics data:', error);
    }
    setIsLoading(false);
  }, [dates]);

  useEffect(() => {
    getAnalyticsData();
  }, [getAnalyticsData]);

  const tiles = [
    {
      item: (
        <div className='metric-card green-card'>
          <div className='k-card-body'>
            <div className='card-header'>
              <div className='card-info'>
                {isLoading && <Loader />}
                <p className='metric-label'>
                  Total Executions for the total number
                </p>
                <TotalExecutionsCard
                  title={'Total Executions for the total number'}
                  subtitle={'Success Rate'}
                  value={passedCount}
                  percentage={(
                    (passedCount / totalExecutions) * 100 || 0
                  ).toFixed(2)}
                  totalExecutions={totalExecutions}
                />
                <div className='donut-container' style={{ zIndex: '-1' }}>
                  <PassVsFailData
                    passedCount={passedCount}
                    failedCount={failedCount}
                    totalExecutions={totalExecutions}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
      resizable: allowReposition,
      reorderable: allowReposition,
      name: 'TotalExecutions',
      id: 1,
      layout: {
        LargeScreenLayout: [{ rowSpan: 1, colSpan: 1 }],
        TabletScreenLayout: [{ rowSpan: 1, colSpan: 2 }],
        MobileScreenLayout: [{ rowSpan: 1, colSpan: 2 }],
      },
    },
    {
      header: (
        <div className='card-header'>
          <Link className='dashboard-action' to={`/autest/executions`}>
            <span className='k-card-title'>Executions Run By Type</span>
          </Link>
        </div>
      ),
      body: (
        <ExecutionTypeData data={analyticsData ?? []} isLoading={isLoading} />
      ),
      resizable: allowReposition,
      reorderable: allowReposition,
      name: 'ExecutionTypeData',
      layout: {
        LargeScreenLayout: [{ colSpan: 2, rowSpan: 2 }],
        TabletScreenLayout: [{ colSpan: 2, rowSpan: 2 }],
        MobileScreenLayout: [{ colSpan: 2, rowSpan: 2 }],
      },
      id: 2,
    },
    {
      header: (
        <div className='card-header'>
          <Link className='dashboard-action' to={`/autest/executions`}>
            <span className='k-card-title'>Tests Run by Types</span>
          </Link>
        </div>
      ),
      body: <TestsRunByType dates={dates} />,
      resizable: allowReposition,
      reorderable: allowReposition,
      name: 'TestsRunByType',
      layout: {
        LargeScreenLayout: [{ colSpan: 2, rowSpan: 2 }],
        TabletScreenLayout: [{ colSpan: 2, rowSpan: 2 }],
        MobileScreenLayout: [{ colSpan: 2, rowSpan: 2 }],
      },
      id: 3,
    },
    {
      item: (
        <div className='metric-card blue-card'>
          <div className='k-card-body'>
            <div className='card-header'>
              <div className='card-info'>
                <p className='metric-label'>Executions Overview</p>
                {isLoading && <Loader />}
                <div className='custom-card'>
                  <div>
                    <div className='sub-title'>
                      <span className='sub-title-text'>Total Executions</span>
                      <span className='value'>{totalExecutions}</span>
                    </div>
                    <div className='sub-title pass-fail-count'>
                      <span className='sub-title-text'>Passed/Failed</span>

                      <span className='value'>
                        {' '}
                        <span className='success'>{passedCount ?? 0}</span>{' '}
                        {' / '}{' '}
                        <span className='failure'> {failedCount ?? 0}</span>
                      </span>
                    </div>
                    <div className='sub-title'>
                      <span className='sub-title-text'>
                        <span className='green-circle'></span>
                        Passed
                      </span>
                      <span className='value'>{passedCount}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
      resizable: allowReposition,
      reorderable: allowReposition,
      name: 'ExecutionsOverView',
      id: 4,
      layout: {
        LargeScreenLayout: [{ colSpan: 1, rowSpan: 1 }],
        TabletScreenLayout: [{ colSpan: 2, rowSpan: 1 }],
        MobileScreenLayout: [{ colSpan: 2, rowSpan: 1 }],
      },
    },
    {
      header: (
        <div className='card-header'>
          <Link className='dashboard-action' to={`/autest/executions`}>
            <span className='k-card-title'>Execution Trends</span>
          </Link>
        </div>
      ),
      body: <TestCaseExecutionTime dates={dates} />,
      resizable: allowReposition,
      reorderable: allowReposition,
      name: 'TestCaseExecutionTime',
      layout: {
        LargeScreenLayout: [{ colSpan: 3, rowSpan: 2 }],
        TabletScreenLayout: [{ colSpan: 2, rowSpan: 2 }],
        MobileScreenLayout: [{ colSpan: 2, rowSpan: 2 }],
      },
      id: 5,
    },
    {
      header: (
        <div className='card-header'>
          <Link className='dashboard-action' to={`/autest/executions`}>
            <span className='k-card-title'>Success/Failure By Date</span>
          </Link>
        </div>
      ),
      body: <SuccessFailure dates={dates} />,
      resizable: allowReposition,
      reorderable: allowReposition,
      name: 'SuccessFailure',
      id: 6,
      layout: {
        LargeScreenLayout: [{ colSpan: 2, rowSpan: 2 }],
        TabletScreenLayout: [{ colSpan: 2, rowSpan: 2 }],
        MobileScreenLayout: [{ colSpan: 2, rowSpan: 2 }],
      },
    },
    {
      header: (
        <div className='card-header'>
          <Link className='dashboard-action' to={`/autest/executions`}>
            <span className='k-card-title'>Test Cases Run by Module</span>
          </Link>
        </div>
      ),
      body: <SuccessByModule dates={dates} />,
      resizable: allowReposition,
      reorderable: allowReposition,
      name: 'SuccessByModule',
      layout: {
        LargeScreenLayout: [{ colSpan: 4, rowSpan: 2 }],
        TabletScreenLayout: [{ colSpan: 2, rowSpan: 2 }],
        MobileScreenLayout: [{ colSpan: 2, rowSpan: 2 }],
      },
      id: 7,
    },
  ];

  /**
   * get Tile Layout Data
   * if data length is less then tiles length
   * it will duplicate data to match no of tiles.
   * @returns {Array} layoutData
   */
  const getDefaultLayout = (tilesData, layout) => {
    const filteredTiles = orderBy(tilesData ?? [], [
      { dir: 'asc', field: 'id' },
    ]);
    const layoutData = getLayout({
      tiles: filteredTiles, // an array of tile objects with ids
      tilelayout,
      deviceInfo,
      layout: layout,
    });

    // Ensure each tile gets its own layout, rather than filling with the same layout
    if (layoutData.length < tilesData.length) {
      return filteredTiles.map(
        (tile) => layoutData.find((l) => l.id === tile.id) || {},
      );
    }
    return layoutData;
  };

  const [data, setData] = useState(getDefaultLayout(tiles, homeDefaultLayout));

  useEffect(() => {
    setData(getDefaultLayout(tiles, homeDefaultLayout));
  }, [deviceInfo]);

  //Default Date option
  const dateRangeOptions = [
    { text: 'Last 7 Days', value: '7' },
    { text: 'Last 14 Days', value: '14' },
    { text: 'Last 30 Days', value: '30' },
    { text: 'Custom Date', value: 'custom' },
  ];

  const [selectedRange, setSelectedRange] = useState(
    JSON.parse(
      localStorage.getItem(`${EXECUTION_DASHBOARD_TILELAYOUT}_DATE_FILTER`),
    )?.range ?? '7',
  );
  const [showCalendar, setShowCalendar] = useState(false);

  /**
   * Handle Date Range Event
   * @param {*} e
   * @returns
   */
  const handleRangeChange = (e) => {
    const value = e.target.value;
    setSelectedRange(value?.value);

    // Handle predefined date range selection
    const today = new Date();
    let startDate;
    switch (value?.value) {
      case '7':
        startDate = new Date(today);
        startDate.setDate(today.getDate() - 7);
        setShowCalendar(false);
        break;
      case '14':
        startDate = new Date(today);
        startDate.setDate(today.getDate() - 14);
        setShowCalendar(false);
        break;
      case '30':
        startDate = new Date(today);
        startDate.setDate(today.getDate() - 30);
        setShowCalendar(false);

        break;
      case 'custom':
        setShowCalendar(true);
        return;
      default:
        return;
    }

    // Set dates for predefined ranges
    let dates = {
      startDate: startDate.toISOString().split('T')[0],
      endDate: today.toISOString().split('T')[0],
    };
    setDates(dates);
    updateSelectedDate(dates, value?.value);
  };

  /**
   * Set Selected Date
   * @param {*} value
   */
  const updateSelectedDate = (value, range) => {
    localStorage.setItem(
      `${EXECUTION_DASHBOARD_TILELAYOUT}_DATE_FILTER`,
      JSON.stringify({ range, dates: value }),
    );
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className='home-page'>
        <Suspense fallback={<Loader />}>
          <section className='tile-header'>
            <div className='date-selector'>
              <DropDownList
                className='date-range-selector'
                data={dateRangeOptions}
                value={dateRangeOptions.find(
                  (option) => option.value === selectedRange,
                )}
                textField='text'
                dataItemKey='value'
                onChange={handleRangeChange}
              />
              {showCalendar && (
                <>
                  <div className='k-px-2'>
                    <label>Start Date:</label>
                    <DatePicker
                      value={new Date(dates.startDate)}
                      format={'MM-dd-yyyy'}
                      onChange={(e) => handleChange(e, 'startDate')}
                    />
                  </div>
                  <div className='k-px-2'>
                    <label>End Date:</label>
                    <DatePicker
                      value={new Date(dates.endDate)}
                      format={'MM-dd-yyyy'}
                      onChange={(e) => handleChange(e, 'endDate')}
                    />
                  </div>
                </>
              )}
              <Button
                className={`action-button`}
                icon={'refresh'}
                onClick={() => {
                  setDates({ ...dates });
                }}
                fillMode='outline'
                themeColor='primary'
              />
            </div>
            <Button
              fillMode={!allowReposition ? 'flat' : 'outline'}
              icon={!allowReposition ? 'edit' : ''}
              themeColor={'primary'}
              className=' edit-tiles'
              onClick={() =>
                saveLayout({
                  tilelayout,
                  data,
                  allowReposition,
                  setAllowReposition,
                  deviceInfo,
                })
              }
            >
              {!allowReposition ? '' : 'Done Editing'}
            </Button>
          </section>
          <TileLayout
            rowHeight={240}
            columns={
              deviceInfo?.type === DEVICE_TYPES.PHONE ||
              deviceInfo?.type === DEVICE_TYPES.TABLET
                ? 4
                : 5
            }
            positions={data}
            gap={{
              rows: 10,
              columns: 10,
            }}
            items={tiles}
            className='charts'
            onReposition={(e) => handleReposition(e, setData)}
          />
        </Suspense>
      </div>
    </ErrorBoundary>
  );
};

export default ExecutionDashboard;
