import React from 'react';
import {
  Chart,
  ChartLegend,
  ChartTooltip,
  ChartSeries,
  ChartSeriesItem,
} from '@progress/kendo-react-charts';
import './ExecutionTypeData.scss'; // Add any additional styles as needed
import { Loader } from 'smart-react';

// Color definitions for execution types
export const colors = {
  ADHOC: '#FFD7BE',
  STRESS: '#BCE3C5 ',
  REGRESSION: '#A1C9F2 ',
};

/**
 * Execution Type Data Chart
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The ExecutionTypeData component.
 */
export const ExecutionTypeData = ({ data, isLoading }) => {
  const chartData =
    data?.length > 0 ? data : [{ ExecutionMethod: 'No Data', Count: 100 }];

  return (
    <>
      {isLoading && <Loader />}
      <Chart>
        <ChartLegend position='bottom' />
        <ChartTooltip
          render={(e) => {
            return (
              <div>
                <strong>{e?.point?.category}</strong>: {e?.point?.value}
              </div>
            );
          }}
        />
        <ChartSeries>
          <ChartSeriesItem
            type='donut'
            data={chartData}
            field='Count'
            categoryField='ExecutionMethod'
            color={(e) => colors[e.category] || '#9e9d9d'}
            visual={(e) => {
              let color = colors[e.category] || '#9e9d9d'; // Default color if not in colors

              // If 'No Data', assign grey color
              if (e.category === 'No Data') {
                color = '#9e9d9d'; // Grey color for 'No Data'
              }
              if (e?.options?.color) {
                color = colors[e.category] || '#9e9d9d'; // Default color if not in colors
                e.options.color = color;
              }
              return e.createVisual();
            }}
          />
        </ChartSeries>
      </Chart>
    </>
  );
};

export default ExecutionTypeData;
