import React from 'react';
import CodeMirror from '@uiw/react-codemirror';
/**
 * data Columns for grid and mobile view
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The Data Columns Array
 */
export const CommandDataColumns = () => {
  const dataColumns = [
    {
      field: 'row_no',
      title: 'Row No',
      editable: false,
      show: false,
      width: '250px',
      width: '250px',
      minResizableWidth: 150,
      filterable: true,
    },
    {
      field: 'tenant_id',
      title: 'Tenant',
      editable: false,
      show: false,
      width: '200px',
      minResizableWidth: 150,
      filterable: false,
    },
    {
      field: 'uc_ossi_cmd',
      title: 'Command',
      editable: false,
      show: true,
      width: '500px',
      minResizableWidth: 50,
      filterable: false,
      cell: (props) => (
        <td>
          <CodeMirror
            value={props.dataItem.uc_ossi_cmd}
            maxHeight='100px'
            options={{
              readOnly: true,
            }}
          />
        </td>
      ),
    },
    {
      field: 'uc_ossi_run_num',
      title: 'Run Num',
      editable: false,
      show: true,
      width: '150px',
      minResizableWidth: 50,
      filterable: false,
    },
    {
      field: 'uc_ossi_exec_ms',
      title: 'Elapsed Milliseconds',
      editable: false,
      show: true,
      width: '150px',
      minResizableWidth: 50,
      filterable: false,
    },
    {
      field: 'uc_ossi_error_msg',
      title: 'Error',
      editable: false,
      show: true,
      width: '150px',
      minResizableWidth: 50,
      filterable: false,
    },
    {
      field: 'uc_ossi_start_ms',
      title: 'Start Time',
      editable: false,
      show: true,
      width: '150px',
      minResizableWidth: 50,
      filterable: false,
    },
  ];

  return dataColumns;
};
export default CommandDataColumns;
